<template>
	<section class="cart center">
		<template v-if="$root.rwd < 2">
			<lottie :options="{ path: '/lottie/Miscanthus01/data.json', autoplay: true, loop: true }" />
		</template>
		<div style="width:100%; max-width:1200px; z-index:2;">
			<quote-title label="購物車" />
			<br>
			<el-card :body-style="{padding:'1em'}">
				<table v-if="$root.rwd < 2">
					<tr>
						<th />
						<th class="theme_text">商品名稱</th>
						<th class="theme_text">售價</th>
						<th class="theme_text">椅友價</th>
						<th class="theme_text">尺寸</th>
						<th class="theme_text">顏色</th>
						<th class="theme_text">數量</th>
						<th class="theme_text">小計</th>
						<th></th>
					</tr>
					<tr v-for="(item, i) in _cart" :key="i">
						<td>
							<thumb :src="item.thumb" style="width:100px;" square />
						</td>
						<td>
							<el-link type="primary">
								<router-link :to="`/product/${item.productId}`">{{item.name}}</router-link>
							</el-link>
						</td>
						<td>{{item.price | currencyFilter}}</td>
						<td>{{item.special_price | currencyFilter}}</td>
						<td>{{item.color || "-"}}</td>
						<td>{{item.size || "-"}}</td>
						<td>{{item.amount}}</td>
						<td>{{getPrice(item) | currencyFilter}}</td>
						<td>
							<popconfirm @confirm="_removeFromCart(item)" placement="left">
								<h3><i class="el-icon-info" style="color:red;" />{{"\u3000"}}確定要刪除？</h3>
								<el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini" />
							</popconfirm>
						</td>
					</tr>
				</table>
				<table v-else style="text-align:left;">
					<template v-for="(item, i) in _cart">
						<tr :key="i">
							<td style="padding:5px;">
								<div class="row" align-y="center" gutter="20">
									<div style="width:100px;">
										<thumb :src="item.thumb" square />
									</div>
									<div class="col" align-x="left">
										<h3>
											<el-link type="primary">
												<router-link :to="`/product/${item.productId}`">{{item.name}}</router-link>
											</el-link>
										</h3>
										<p class="theme_text">
											<template v-if="item.color || item.size">
												顏色：{{item.color || "-"}}{{"\u3000"}}/{{"\u3000"}}尺寸：{{item.size || "-"}}<br>
											</template>
											數量：{{item.amount}}<br>
											<b>{{getPrice(item) | currencyFilter}}</b>
										</p>
									</div>
								</div>
							</td>
						</tr>
					</template>
				</table>
				<br>
				<div class="row" align-x="right" align-y="center" gutter="20" style="font-size:1.7em;">
					<small><b>總{{"\u3000"}}{{"\u3000"}}計<small>（+運費100元）</small></b></small>
					<b><span style="color:#cb2222;">{{totalPrice | currencyFilter}}</span>{{"\u3000"}}<small>元</small></b>
				</div>
			</el-card>
			<section style="padding:5%;">
				<quote-title label="訂購資訊" />
				<br>
				<order-form ref="orderForm" />

				<!-- 注意事項 -->
				<precautions />
			</section>
			<div class="center">
				<el-button @click="handleOrder()" :disabled="!_cart.length" type="theme" size="big" round>送出訂單</el-button>
			</div>
		</div>
		<hint-on-mounted ref="hint-modal" />
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	components: {
		Popconfirm: () => import("@/components/popconfirm"),
		OrderForm: () => import("@/components/cart/order-form"),
		HintOnMounted: () => import("@/components/cart/hint-on-mounted"),
		Precautions: () => import("@/components/cart/precautions"),
		Thumb: () => import("@/components/thumb"),
	},
	data() {
		return {
			coupon: ""
		}
	},
	computed: {
		...mapState("order", ["_cart"]),
		...mapState("auth", ["_auth"]),
		totalPrice() {
			return this._cart
				.reduce((a, b) => a + this.getPrice(b), 0)
				+ 100;
		}
	},
	methods: {
		...mapActions("order", ["_removeFromCart", "_placeOrder"]),
		getPrice({ price, special_price, amount }) {
			const { roles = new Array } = this._auth || new Object;
			return (
				roles.includes("donor")
					? special_price
					: price
			) * amount;
		},
		async handleOrder() {
			if (await this.$refs["hint-modal"].check()) {
				try {
					this._placeOrder({
						...await this.$refs["orderForm"].validate(),
						list: this._cart
					}).then(res => this.$message.success(res));
				} catch (error) {
					this.$message.error(error);
					this.$refs["orderForm"].$el.scrollIntoView({ behavior: "smooth" });
				}
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.cart {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../assets/background/brush2.png"),
		url("../../assets/background/dot1.png"),
		url("../../assets/background/dot2.png");
	background-position: center 70%, center 80%, center 80%;

	.lottie {
		position: absolute;
		width: 25%;
		max-width: 250px;
		bottom: 0;
		left: 5%;
		transform: translateY(20%);
	}
}
.el-card {
	border-radius: 25px;
}
.el-divider {
	height: 2px;
	background: $theme-light;
	margin-top: 0;
}
table {
	width: 100%;
	background: #fff;
	border-radius: 10px;
	text-align: center;
}
tr:nth-child(even) {
	background: $theme-background;
}
th,
td {
	padding: 1em 2em;
}
</style>